import * as requestFromServer from './commentsCrud'
import { commentsSlice, callTypes } from './commentsSlice'

const { actions } = commentsSlice

export const fetchComments = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllComments(queryParams)
		dispatch(actions.commentsFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}

export const fetchComment = (id) => async (dispatch) => {
	if (!id) {
		return dispatch(actions.commentFetched({ commentForEdit: undefined }))
	}

	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const data = await requestFromServer.getCommentById(id)
		dispatch(actions.commentFetched({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const deleteComment = (id) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.deleteComment(id)
		dispatch(actions.commentDeleted({ id }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const createComment = (commentForCreation) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const data = await requestFromServer.createComment(commentForCreation)
		dispatch(actions.commentCreated({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const updateComment = (comment) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.updateComment(comment)
		dispatch(actions.commentUpdated({ comment }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const vote = (data) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const comment = await requestFromServer.vote(data)
		dispatch(actions.commentVoted({ comment }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}
