import React, { useState } from 'react'
import { CategoryCreateModal } from './Create'
import { shallowEqual, useSelector } from 'react-redux'

export const Category = () => {
	const [show, setShow] = useState(false)
	const [data, setData] = useState()
	const { categories } = useSelector(
		({ categories }) => ({
			categories: categories.entities,
		}),
		shallowEqual
	)
	const onHide = () => {
		setShow(false)
	}
	return (
		<>
			<div className='d-flex flex-column-fluid'>
				<div className='d-flex flex-column-fluid'>
					{/*begin::Container*/}
					<div className='container'>
						{/*begin::Row*/}
						<div className='row'>
							<div className='col-xl-6 m-auto'>
								{/*begin::List Widget 4*/}
								<div className='card card-custom card-stretch gutter-b'>
									{/*begin::Header*/}
									<div className='card-header border-0'>
										<h3 className='card-title font-weight-bolder text-dark'>
											Categories
										</h3>
										<div className='card-toolbar'>
											<div className='dropdown dropdown-inline'>
												<button
													onClick={() => {
														setData()
														setShow(true)
													}}
													className='btn btn-light btn-sm font-size-sm font-weight-bolder text-dark-75'
												>
													Create
												</button>
											</div>
										</div>
									</div>
									{/*end::Header*/}
									{/*begin::Body*/}
									<div className='card-body pt-2'>
										{categories.length
											? categories.map((category, i) => (
													<div
														key={i}
														className='d-flex align-items-center mb-5'
													>
														{/*begin::Bullet*/}
														<span className='bullet bullet-bar bg-success align-self-stretch'></span>
														{/*end::Bullet*/}
														{/*begin::Checkbox*/}
														<label className='checkbox checkbox-lg checkbox-light-success checkbox-inline flex-shrink-0 m-0 mx-4'>
															<input type='checkbox' name='select' value='1' />
															<span></span>
														</label>
														{/*end::Checkbox*/}
														{/*begin::Text*/}
														<div className='d-flex flex-column flex-grow-1'>
															<span className='text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1'>
																{category.name}
															</span>
														</div>
														{/*end::Text*/}
														{/*begin::Dropdown*/}
														<div
															className='dropdown dropdown-inline ml-2'
															data-toggle='tooltip'
															title='Quick actions'
															data-placement='left'
														>
															{' '}
															<div
																onClick={() => {
																	setData(category)
																	setShow(true)
																}}
																className='btn btn-hover-light-primary btn-sm btn-icon'
															>
																<span className='svg-icon svg-icon-lg text-primary ml-1 svg-icon-sort'>
																	<span className='material-icons'>edit</span>
																</span>
															</div>
														</div>
														{/*end::Dropdown*/}
													</div>
											  ))
											: 'No categories yet'}
									</div>
									{/*end::Body*/}
								</div>
								{/*end:List Widget 4*/}
							</div>
						</div>
						{/*end::Row*/}
					</div>
					{/*end::Container*/}
				</div>
			</div>
			<CategoryCreateModal show={show} onHide={onHide} data={data} />
		</>
	)
}
