import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import {
	createCategory,
	updateCategory,
} from './_redux/categories/categoriesActions'

export const CategoryCreateModal = ({ show, onHide, data }) => {
	const initialValues = { name: '' }
	const dispatch = useDispatch()
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: data || initialValues,
		validationSchema: Yup.object().shape({
			name: Yup.string()
				.max(40, 'Maximum 40 characters')
				.required('Title is required'),
		}),
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(true)
			if (values.id)
				dispatch(updateCategory(values))
					.then(() => {
						setSubmitting(false)
						resetForm()
						onHide()
					})
					.catch(() => setSubmitting(false))
			else
				dispatch(createCategory(values))
					.then(() => {
						setSubmitting(false)
						resetForm()
						onHide()
					})
					.catch(() => setSubmitting(false))
		},
	})
	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}
	return (
		<Modal
			show={show}
			onHide={onHide}
			size='md'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					Post an Idea
				</Modal.Title>
			</Modal.Header>
			<form className='form'>
				<Modal.Body>
					{/*begin::Form*/}
					<div className='row'>
						<div className='col-xl-8 m-auto'>
							{/*begin::Input*/}
							<div className='form-group'>
								<label>
									Name<span className='text-danger'>*</span>
								</label>
								<input
									type='text'
									className={`form-control form-control-solid form-control-lg ${getInputClasses(
										'name',
									)}`}
									placeholder='Enter category name'
									{...formik.getFieldProps('name')}
								/>
								{formik.touched.name && formik.errors.name && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.name}</div>
									</div>
								)}
							</div>
						</div>
					</div>
					{/*end::Form*/}
				</Modal.Body>
				<Modal.Footer>
					<button
						type='reset'
						onClick={onHide}
						className='btn btn-clean font-weight-bold'
					>
						Cancel
					</button>
					<Button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
						Submit
						{formik.isSubmitting && (
							<span className='mx-3 spinner spinner-white'></span>
						)}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}
