import React, { useState } from 'react'
import { Switch } from 'react-router-dom'
import { ContentRoute } from '../../../_metronic/layout'
import { CategorySidebar } from '../../components/CategorySidebar'
import { Feedback } from './Feedback'
import { FeedbackFetch } from './FetchPage'

export function FeedbackPage() {
	const [cat, setCat] = useState()
	const [initialFilter, setInitialFilter] = useState({
		filter: {},
		sortOrder: 'desc', // asc||desc
		sortField: 'updatedAt',
		pageNumber: 1,
		pageSize: 20,
	})
	const setFilter = (v) =>
		setInitialFilter({
			...initialFilter,
			filter: { ...v },
		})
	return (
		<div className='d-flex flex-column-fluid'>
			{/*begin::Container*/}
			<div className='container'>
				<div className='d-flex flex-row'>
					{/*begin::Profile Personal Information*/}
					<CategorySidebar
						setFilter={setFilter}
						filter={initialFilter.filter}
						cat={cat}
					/>
					{/*end::Aside*/}
					<Switch>
						<ContentRoute path='/feedbacks/:id'>
							<FeedbackFetch setCat={setCat} />
						</ContentRoute>
						<ContentRoute path='/feedbacks'>
							<Feedback
								initialFilter={initialFilter}
								setInitialFilter={setInitialFilter}
							/>
						</ContentRoute>
					</Switch>
				</div>
			</div>
		</div>
	)
}
