import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

export const CategorySidebar = ({ setFilter, filter, cat }) => {
	const [selected, setSelected] = useState([])
	useEffect(() => {
		if (cat) setSelected([cat])
	}, [cat])

	const { categories } = useSelector(
		({ categories }) => ({
			categories: categories.entities,
		}),
		shallowEqual
	)
	useEffect(() => {
		const data = { ...filter }
		if (selected.length) data.category_id = selected
		else delete data.category_id
		setFilter(data)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected])

	const getSelected = (id, status) => {
		let data = new Set(selected)
		if (status) data.add(id)
		else data.delete(id)
		setSelected(Array.from(data))
	}

	return (
		<div className='flex-row-auto w-250px w-xxl-350px' id='kt_profile_aside'>
			{/*begin::Profile Card*/}
			<div className='card card-custom card-stretch'>
				<div className='card-header py-3'>
					<div className='card-title align-items-start flex-column'>
						<h3 className='card-label font-weight-bold text-dark'>Category</h3>
					</div>
				</div>
				{/*begin::Body*/}
				<div className='card-body pt-0'>
					{categories.map((category, i) => (
						<div key={i}>
							{/*begin::Content*/}
							<div className='d-flex align-items-center flex-grow-1'>
								{/*begin::Checkbox*/}
								<label className='checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4'>
									<input
										type='checkbox'
										value={category.id}
										onChange={(e) => getSelected(category.id, e.target.checked)}
										checked={selected.includes(category.id)}
									/>
									<span></span>
								</label>
								{/*end::Checkbox*/}
								{/*begin::Section*/}
								<div className='d-flex flex-wrap align-items-center justify-content-between w-100'>
									{/*begin::Info*/}
									<div className='d-flex flex-column align-items-cente py-2 w-75'>
										{/*begin::Title*/}
										<span className='font-size-lg mb-1'>{category.name}</span>
										{/*end::Title*/}
									</div>
									{/*end::Info*/}
								</div>
								{/*end::Section*/}
							</div>
							{/*end::Content*/}
						</div>
					))}
				</div>
				{/*end::Body*/}
			</div>
			{/*end::Profile Card*/}
		</div>
	)
}
