import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FeedbackCreateModal } from './Create'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchFeedbacks } from './_redux/feedbacks/feedbacksActions'
import moment from 'moment'
import { Pagination } from '../../../_metronic/_partials/controls'
import { sizePerPageList } from '../../components/Helper'

export const Feedback = ({ initialFilter, setInitialFilter }) => {
	const [show, setShow] = useState(false)
	const onHide = () => {
		setShow(false)
	}
	const dispatch = useDispatch()
	const { currentState } = useSelector(
		({ feedbacks }) => ({
			currentState: feedbacks,
		}),
		shallowEqual
	)
	const { entities, listLoading, totalCount } = currentState
	useEffect(() => {
		dispatch(fetchFeedbacks(initialFilter))
	}, [dispatch, initialFilter])

	return (
		<>
			<div className='flex-row-fluid ml-lg-8'>
				{/*begin::Card*/}
				<div className='card card-custom card-stretch'>
					{/*begin::Header*/}
					<div className='card-header py-3'>
						<div className='card-title align-items-start flex-column'>
							<h3 className='card-label font-weight-bolder text-dark'>
								FeedBacks
							</h3>
							<span className='text-muted font-weight-bold font-size-sm mt-1'>
								It's good to hear from you
							</span>
						</div>
						<div className='card-toolbar'>
							<button
								type='button'
								onClick={() => setShow(true)}
								className='btn btn-primary mr-2'
							>
								New
							</button>
						</div>
					</div>
					{/*end::Header*/}
					<div className='card-body'>
						<Pagination
							isLoading={listLoading}
							paginationProps={{
								totalSize: totalCount,
								dataSize: totalCount,
								sizePerPage: initialFilter.pageSize,
								page: initialFilter.pageNumber,
								paginationSize: totalCount / initialFilter.pageSize,
								sizePerPageList: sizePerPageList,
								pageStartIndex: 1,
								custom: true,
								onPageChange: (v) => {
									setInitialFilter({ ...initialFilter, pageNumber: v })
								},
								onSizePerPageChange: (v) => {
									setInitialFilter({
										...initialFilter,
										pageNumber: 1,
										pageSize: v,
									})
								},
							}}
						>
							{entities.length > 0
								? entities.map((feedback, i) => (
										<div key={i}>
											<div className='row my-5'>
												{/*begin: Pricing*/}
												<div className='col-md-9 col-xxl-9'>
													{/*begin::Content*/}
													<div className='mb-4'>
														<div>
															<Link
																to={`/feedbacks/${feedback.id}`}
																className='font-size-h4 font-weight-bolder'
															>
																{feedback.title}
															</Link>
														</div>
														<small className='font-weight-bolder'>
															{feedback.category}
														</small>
													</div>
													<div className='line-height-lg mb-8'>
														<p
															dangerouslySetInnerHTML={{
																__html: feedback.description
																	.substring(0, 300)
																	.concat(
																		feedback.description.length > 300
																			? ' ...'
																			: ''
																	),
															}}
														></p>
														<small className='px-2'>{feedback.status}</small>
														<small className='px-2 font-weight-bold'>
															Posted by {feedback.created_by?.name}
														</small>
														<small className='px-2 font-weight-bold'>
															Updated {moment(feedback.created_at).calendar()}
														</small>
													</div>
													{/*end::Content*/}
												</div>
												{/*end: Pricing*/}
											</div>
											<hr />
										</div>
								  ))
								: !listLoading && <center>No Feedbacks at the moment</center>}
						</Pagination>
					</div>
				</div>
			</div>
			<FeedbackCreateModal show={show} onHide={onHide} />
		</>
	)
}
