import { createSlice } from '@reduxjs/toolkit'

const initialFeedbacksState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	feedbackForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const feedbacksSlice = createSlice({
	name: 'feedbacks',
	initialState: initialFeedbacksState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getFeedbackById
		feedbackFetched: (state, action) => {
			state.actionsLoading = false
			state.feedbackForEdit = action.payload.data
			state.error = null
		},
		// findFeedbacks
		feedbacksFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createFeedback
		feedbackCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateFeedback
		feedbackUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			if (state.feedbackForEdit?.id === action.payload.feedback.id) {
				state.feedbackForEdit = {
					...state.feedbackForEdit,
					...action.payload.feedback,
				}
			}
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.feedback.id) {
					return action.payload.feedback
				}
				return entity
			})
		},
		statusUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.id) {
					entity.status = action.payload.status
				}
				return entity
			})
		},
		// deleteFeedback
		feedbackDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteFeedbacks
		feedbacksDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// feedbacksUpdateState
		feedbacksStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
