import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import './ck.css'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
	createFeedback,
	updateFeedback,
} from './_redux/feedbacks/feedbacksActions'

export const FeedbackCreateModal = (props) => {
	const { categories } = useSelector(
		({ categories }) => ({
			categories: categories.entities,
		}),
		shallowEqual
	)

	const dispatch = useDispatch()
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			id: props.data?.id || undefined,
			title: props.data?.title || '',
			category_id: props.data?.category_id || '',
			description: props.data?.description || '',
		},
		validationSchema: Yup.object().shape({
			title: Yup.string()
				.max(40, 'Maximum 40 characters')
				.required('Title is required'),
			description: Yup.string()
				.max(20000, 'Maximum 20000 symbols')
				.required('Description is required'),
			category_id: Yup.string().required('Category is required'),
		}),
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(true)
			if (values.id)
				dispatch(updateFeedback(values))
					.then(() => {
						setSubmitting(false)
						resetForm()
						props.onHide()
					})
					.catch(() => setSubmitting(false))
			else
				dispatch(createFeedback(values))
					.then(() => {
						setSubmitting(false)
						resetForm()
						props.onHide()
					})
					.catch(() => setSubmitting(false))
		},
	})
	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}
	return (
		<Modal
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					Post a Feedback
				</Modal.Title>
			</Modal.Header>
			<form className='form'>
				<Modal.Body>
					{/*begin::Form*/}
					<div className='row'>
						<div className='col-xl-8 m-auto'>
							{/*begin::Input*/}
							<div className='form-group'>
								<label>
									Title<span className='text-danger'>*</span>
								</label>
								<input
									type='text'
									className={`form-control form-control-solid form-control-lg ${getInputClasses(
										'title'
									)}`}
									placeholder='Enter your feedback title'
									{...formik.getFieldProps('title')}
								/>
								{formik.touched.title && formik.errors.title && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>{formik.errors.title}</div>
									</div>
								)}
							</div>
							<div className='form-group'>
								<label>
									Category<span className='text-danger'>*</span>
								</label>
								<select
									className={`form-control form-control-solid form-control-lg ${getInputClasses(
										'category_id'
									)}`}
									{...formik.getFieldProps('category_id')}
								>
									<option value=''>Select a category</option>
									{categories.map((category, i) => (
										<option key={i} value={category.id}>
											{category.name}
										</option>
									))}
								</select>
								{formik.touched.category_id && formik.errors.category_id && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											{formik.errors.category_id}
										</div>
									</div>
								)}
							</div>
							{/*end::Input*/}
							{/*begin::Input*/}
							<div className='form-group'>
								<label htmlFor='exampleTextarea'>
									Description<span className='text-danger'>*</span>
								</label>
								<CKEditor
									editor={ClassicEditor}
									data={formik.values.description}
									config={{ height: 500 }}
									onChange={(event, editor) => {
										const data = editor.getData()
										formik.setFieldValue('description', data)
									}}
								/>
								{formik.touched.description && formik.errors.description && (
									<div className='fv-plugins-message-container'>
										<div className='fv-help-block'>
											{formik.errors.description}
										</div>
									</div>
								)}
							</div>
							{/*end::Input*/}
						</div>
					</div>
					{/*end::Form*/}
				</Modal.Body>
				<Modal.Footer>
					<button
						type='reset'
						onClick={props.onHide}
						className='btn btn-clean font-weight-bold'
					>
						Cancel
					</button>
					<Button onClick={formik.handleSubmit}>
						Post Feedback
						{formik.isSubmitting && (
							<span className='mx-3 spinner spinner-white'></span>
						)}
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	)
}
