import { createSlice } from '@reduxjs/toolkit'

const initialIdeasState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	ideaForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const ideasSlice = createSlice({
	name: 'ideas',
	initialState: initialIdeasState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getIdeaById
		ideaFetched: (state, action) => {
			state.actionsLoading = false
			state.ideaForEdit = action.payload.data
			state.error = null
		},
		// findIdeas
		ideasFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createIdea
		ideaCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.push(action.payload.data)
		},
		// updateIdea
		ideaUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			if (state.ideaForEdit?.id === action.payload.idea.id) {
				state.ideaForEdit = {
					...state.ideaForEdit,
					...action.payload.idea,
				}
			}
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.idea.id) {
					return action.payload.idea
				}
				return entity
			})
		},
		// updateIdea
		ideaVoted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.ideaForEdit = { ...state.ideaForEdit, ...action.payload.idea }
		},
		statusUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.id) {
					entity.status = action.payload.status
				}
				return entity
			})
		},
		// deleteIdea
		ideaDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteIdeas
		ideasDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// ideasUpdateState
		ideasStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
