import * as requestFromServer from './feedbacksCrud'
import { feedbacksSlice, callTypes } from './feedbacksSlice'

const { actions } = feedbacksSlice

export const fetchFeedbacks = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllFeedbacks(queryParams)
		dispatch(actions.feedbacksFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
	}
}

export const fetchFeedback = (id) => async (dispatch) => {
	if (!id) {
		return dispatch(actions.feedbackFetched({ feedbackForEdit: undefined }))
	}

	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const data = await requestFromServer.getFeedbackById(id)
		dispatch(actions.feedbackFetched({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const createFeedback = (feedbackForCreation) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const data = await requestFromServer.createFeedback(feedbackForCreation)
		dispatch(actions.feedbackCreated({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const updateFeedback = (feedback) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.updateFeedback(feedback)
		dispatch(actions.feedbackUpdated({ feedback }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const deleteFeedback = (id) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.deleteFeedback(id)
		dispatch(actions.feedbackDeleted({ id }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}
