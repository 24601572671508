import { toast } from 'react-toastify'
import { login_using_refresh_token } from '../app/modules/Auth/_redux/authCrud'
import * as auth from '../app/modules/Auth/_redux/authRedux'

export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		(config) => {
			const {
				auth: { authToken },
			} = store.getState()

			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`
			}

			return config
		},
		(err) => Promise.reject(err),
	)
	axios.interceptors.response.use(
		(response) => {
			response.data?.message && toast.success(response.data.message)
			return response.data.data
		},
		async (error) => {
			let {
				auth: { refreshToken, microsolution },
			} = store.getState()
			const originalRequest = error.config
			if (
				error.response.status === 403 &&
				(refreshToken || microsolution) &&
				!originalRequest._retry
			) {
				try {
					originalRequest._retry = true
					const splashScreen = document.getElementById('splash-screen')
					if (splashScreen) splashScreen.style.display = ''
					const data = {
						refresh_token: refreshToken,
						key: microsolution?.app_key_hash,
						module_id: microsolution?.currentModule?.id,
					}
					const response = await login_using_refresh_token(data)
					if (splashScreen) splashScreen.style.display = 'none'
					if (response.status === 200) {
						store.dispatch(
							auth.actions.login({
								authToken: response.auth_token,
								refreshToken: response.refresh_token,
								user: response.user,
							}),
						)
						return axios(originalRequest)
					} else {
						store.dispatch(auth.actions.logout())
						throw error
					}
				} catch (error) {
					store.dispatch(auth.actions.logout())
					throw error
				}
			} else if (error.response.status === 401) {
				store.dispatch(auth.actions.logout())
				throw error
			} else {
				error.response.data?.message && toast.error(error.response.data.message)
				throw error
			}
		},
	)
}
