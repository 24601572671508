import * as requestFromServer from './ideasCrud'
import { ideasSlice, callTypes } from './ideasSlice'

const { actions } = ideasSlice

export const fetchIdeas = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllIdeas(queryParams)
		dispatch(actions.ideasFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}

export const fetchIdea = (id) => async (dispatch) => {
	if (!id) {
		return dispatch(actions.ideaFetched({ ideaForEdit: undefined }))
	}

	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const data = await requestFromServer.getIdeaById(id)
		dispatch(actions.ideaFetched({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const createIdea = (ideaForCreation) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const data = await requestFromServer.createIdea(ideaForCreation)
		dispatch(actions.ideaCreated({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const updateIdea = (idea) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.updateIdea(idea)
		dispatch(actions.ideaUpdated({ idea }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const deleteIdea = (id) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.deleteIdea(id)
		dispatch(actions.ideaDeleted({ id }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}
