import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { categoriesSlice } from '../app/pages/Category/_redux/categories/categoriesSlice'
import { feedbacksSlice } from '../app/pages/Feedback/_redux/feedbacks/feedbacksSlice'
import { ideasSlice } from '../app/pages/Idea/_redux/ideas/ideasSlice'
import { commentsSlice } from '../app/pages/Idea/_redux/comments/commentsSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	categories: categoriesSlice.reducer,
	feedbacks: feedbacksSlice.reducer,
	ideas: ideasSlice.reducer,
	comments: commentsSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
