import { createSlice } from '@reduxjs/toolkit'

const initialCommentsState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: [],
	commentForEdit: undefined,
	lastError: null,
}
export const callTypes = {
	list: 'list',
	action: 'action',
}

export const commentsSlice = createSlice({
	name: 'comments',
	initialState: initialCommentsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		// getCommentById
		commentFetched: (state, action) => {
			state.actionsLoading = false
			state.commentForEdit = action.payload.data
			state.error = null
		},
		// findComments
		commentsFetched: (state, action) => {
			const { totalCount, entities } = action.payload
			state.listLoading = false
			state.error = null
			state.entities = entities
			state.totalCount = totalCount
		},
		// createComment
		commentCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.entities.unshift(action.payload.data)
		},
		// updateComment
		commentUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.comment.id) {
					return action.payload.comment
				}
				return entity
			})
		},
		// updateComment
		commentVoted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.commentForEdit = {
				...state.commentForEdit,
				...action.payload.comment,
			}
		},
		statusUpdated: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.map((entity) => {
				if (entity.id === action.payload.id) {
					entity.status = action.payload.status
				}
				return entity
			})
		},
		// deleteComment
		commentDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => el.id !== action.payload.id,
			)
		},
		// deleteComments
		commentsDeleted: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.entities = state.entities.filter(
				(el) => !action.payload.ids.includes(el.id),
			)
		},
		// commentsUpdateState
		commentsStatusUpdated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			const { ids, status } = action.payload
			state.entities = state.entities.map((entity) => {
				if (ids.findIndex((id) => id === entity.id) > -1) {
					entity.status = status
				}
				return entity
			})
		},
	},
})
