import axios from 'axios'
const IDEA_API = process.env.REACT_APP_API_URL + '/ideas'

export function createIdea(data) {
	return axios.post(IDEA_API, data)
}

export function updateIdea(data) {
	return axios.put(IDEA_API, data)
}

export function getAllIdeas(queryParams = {}) {
	return axios.get(IDEA_API + '?queryParams=' + JSON.stringify(queryParams))
}

export function getIdeaById(id) {
	return axios.get(IDEA_API + '/' + id)
}

export function vote(data) {
	return axios.post(IDEA_API + '/vote', data)
}

export function deleteIdea(id) {
	return axios.delete(IDEA_API + '/' + id)
}
