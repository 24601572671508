import axios from 'axios'
const CATEGORY_API = process.env.REACT_APP_API_URL + '/categories'

export function createCategory(data) {
	return axios.post(CATEGORY_API, data)
}

export function updateCategory(data) {
	return axios.put(CATEGORY_API, data)
}

export function getAllCategories() {
	return axios.get(CATEGORY_API)
}
