import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { IdeaCreateModal } from './Create'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { deleteIdea, fetchIdea, updateIdea } from './_redux/ideas/ideasActions'
import moment from 'moment'
import {
	createComment,
	deleteComment,
	fetchComments,
	updateComment,
} from './_redux/comments/commentsActions'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { vote } from './_redux/ideas/ideasCrud'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'
import { Dropdown } from 'react-bootstrap'

export const IdeaFetch = ({ setCat }) => {
	const [data, setData] = useState()
	const { id } = useParams()
	const [show, setShow] = useState(false)
	const initialFilter = {
		filter: { parent_id: id },
		sortOrder: 'desc', // asc||desc
		sortField: 'createdAt',
		pageNumber: 1,
		pageSize: 20,
	}
	const [initialComment, setInitialComment] = useState({
		comment: '',
	})
	const onHide = () => {
		setShow(false)
	}
	const dispatch = useDispatch()
	const { idea, ideas, isLoading, comments, user } = useSelector(
		({ ideas, comments, auth }) => ({
			ideas: ideas.entities,
			idea: ideas.ideaForEdit,
			isLoading: ideas.actionsLoading,
			comments: comments.entities,
			user: auth.user,
		}),
		shallowEqual
	)

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialComment,
		validationSchema: Yup.object().shape({
			comment: Yup.string()
				// .max(40, 'Maximum 40 characters')
				.required('Comment is required'),
		}),
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(true)
			values.parent_id = id
			if (values.id)
				dispatch(updateComment(values))
					.then(() => {
						setSubmitting(false)
						setInitialComment({
							comment: '',
						})
					})
					.catch(() => setSubmitting(false))
			else
				dispatch(createComment(values))
					.then(() => {
						setSubmitting(false)
						resetForm()
					})
					.catch(() => setSubmitting(false))
		},
	})
	useEffect(() => {
		dispatch(fetchIdea(id))
		dispatch(fetchComments(initialFilter))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id])
	useEffect(() => {
		if (idea?.id === Number(id)) setData(idea)
		else {
			const d = ideas.find((i) => i.id === Number(id))
			setData(d)
		}
	}, [ideas, idea, id])
	const Vote = (status) => {
		const newVote = {
			id,
			vote_count:
				data.vote === true || data.vote === false
					? data.vote_count - 1
					: data.vote_count,
			vote: undefined,
			points:
				data.vote === true
					? data.points - 10
					: data.vote === false
					? data.points + 10
					: data.points,
		}
		if (status === true || status === false) {
			newVote.vote = status
			newVote.vote_count += 1
			if (status === true) newVote.points += 10
			else if (status === false) newVote.points -= 10
		}
		setData({ ...data, ...newVote })
		vote(newVote)
	}
	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return 'is-invalid'
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return 'is-valid'
		}

		return ''
	}
	const handleDelete = (id) => {
		dispatch(deleteComment(id))
	}
	const history = useHistory()
	const del = () => {
		dispatch(deleteIdea(id)).then(() => history.push('/ideas'))
	}
	const updateStatus = (status) => {
		dispatch(updateIdea({ id: Number(id), status }))
	}
	return (
		<>
			<div className='row'>
				{!data && isLoading ? (
					<center>
						<span className='m-auto spinner spinner-dark spinner-lg'></span>
					</center>
				) : data ? (
					<>
						<div className='col-md-8 col-lg-9'>
							{/*begin::Example*/}
							{/*begin::Card*/}
							<div className='card card-custom mb-5'>
								<div className='card-header border-0'>
									<u className='card-title font-size-h1 font-weight-bolder text-dark'>
										{data.title}
									</u>
									<div className='card-toolbar'>
										<Link
											to='/ideas'
											className='btn btn-sm btn-icon btn-bg-light text-secondary btn-hover-secondary mx-1'
											onClick={() => setShow(true)}
										>
											<span className='material-icons'>arrow_back</span>
										</Link>
										{((data.created_by.id === user?.user_detail.account_id &&
											comments.length === 0) ||
											user.role_details.role_level <= 4) && (
											<>
												<button
													className='btn btn-sm btn-icon btn-bg-light text-primary btn-hover-primary mx-1'
													onClick={() => setShow(true)}
												>
													<span className='material-icons'>edit</span>
												</button>
												<button
													className='btn btn-sm btn-icon btn-bg-light text-danger btn-hover-danger mx-1'
													onClick={del}
												>
													<span className='material-icons'>delete</span>
												</button>
											</>
										)}
										{user.role_details.role_level <= 4 && (
											<Dropdown
												className='dropdown-inline'
												drop='down'
												alignRight
											>
												<Dropdown.Toggle
													className='btn btn-transparent-primary btn-sm font-weight-bolder dropdown-toggle px-5 text-capitalize'
													variant='transparent'
													id='dropdown-toggle-top'
												>
													{data.status}
												</Dropdown.Toggle>
												<Dropdown.Menu className='dropdown-menu dropdown-menu-sm dropdown-menu-right'>
													<ul className='navi navi-hover'>
														<li className='navi-header pb-1'>
															<span className='text-primary text-uppercase font-weight-bold font-size-sm'>
																Update Status:
															</span>
														</li>
														{[
															'open',
															'in development',
															'in review',
															'delivered',
															'archived',
															'rejected',
															'hidden',
															'closed',
														].map((status, i) => (
															<li
																className='navi-item'
																onClick={() => updateStatus(status)}
																key={i}
															>
																<div className='navi-link'>
																	<span className='navi-text text-capitalize'>
																		{status}
																	</span>
																</div>
															</li>
														))}
													</ul>
												</Dropdown.Menu>
											</Dropdown>
										)}
									</div>
								</div>
								<div className='card-body'>
									{/*begin::Content*/}
									<div className='line-height-lg mb-4'>
										<p
											dangerouslySetInnerHTML={{
												__html: data.description,
											}}
										></p>
										<Link to='/ideas' onClick={() => setCat(data.category_id)}>
											{data.category}
										</Link>
									</div>
									{/*end::Content*/}
								</div>
							</div>
							{/*end::Card*/}
							{/*begin::Card*/}
							<div className='card card-custom gutter-b' id='comment_form'>
								{/*begin::Header*/}
								<div className='card-header card-header-tabs-line'>
									<div className='card-toolbar'>COMMENTS</div>
								</div>
								{/*end::Header*/}
								{/*begin::Body*/}
								<div className='card-body px-0'>
									<div className='tab-content pt-5'>
										{/*begin::Tab Content*/}
										<div
											className='tab-pane active'
											id='kt_apps_contacts_view_tab_1'
											role='tabpanel'
										>
											<div className='container'>
												<form className='form' onSubmit={formik.handleSubmit}>
													<div className='form-group'>
														<textarea
															className={`form-control form-control-lg form-control-solid ${getInputClasses(
																'comment'
															)}`}
															id='exampleTextarea'
															rows='3'
															placeholder='Share a comment'
															{...formik.getFieldProps('comment')}
														></textarea>
													</div>
													<div className='row'>
														<div className='col'>
															<button
																type='submit'
																className='btn btn-light-primary font-weight-bold'
															>
																Share
																{formik.isSubmitting && (
																	<span className='mx-3 spinner spinner-white'></span>
																)}
															</button>
														</div>
													</div>
												</form>
												<div className='separator separator-dashed my-10'></div>
												{/*begin::Timeline*/}
												<div className='timeline timeline-3'>
													<div className='timeline-items'>
														{comments.map((comment) => (
															<div key={comment.id} className='timeline-item'>
																<div className='timeline-media'>
																	<img
																		alt='Pic'
																		src={toAbsoluteUrl('/media/default.jpg')}
																	/>
																</div>
																<div className='timeline-content'>
																	<div className='d-flex align-items-center justify-content-between mb-3'>
																		<div className='mr-2'>
																			<span className='text-dark-75 text-hover-primary font-weight-bold'>
																				{comment.created_by?.name}
																			</span>
																			<span className='text-muted ml-2'>
																				{moment(comment.created_at).calendar()}
																			</span>
																			{/* <span className='label label-light-success font-weight-bolder label-inline ml-2'>
																					new
																				</span> */}
																		</div>
																		{comment.created_by?.id ===
																			user.user_detail.account_id && (
																			<div
																				className='dropdown ml-2'
																				data-toggle='tooltip'
																				title='Edit'
																				data-placement='left'
																			>
																				{/* <a
																					href='#comment_form'
																					type='button'
																					className='btn btn-hover-light-primary btn-sm btn-icon mx-1'
																					onClick={() =>
																						setInitialComment(comment)
																					}
																				>
																					<i className='flaticon2-pen icon-md'></i>
																				</a> */}
																				<button
																					type='button'
																					className='btn btn-hover-light-danger btn-sm btn-icon mx-1'
																					onClick={() =>
																						handleDelete(comment.id)
																					}
																				>
																					<i className='flaticon-delete icon-md'></i>
																				</button>
																			</div>
																		)}
																	</div>
																	<p className='p-0'>{comment.comment}</p>
																</div>
															</div>
														))}
													</div>
												</div>
												{/*end::Timeline*/}
											</div>
										</div>
										{/*end::Tab Content*/}
									</div>
								</div>
								{/*end::Body*/}
							</div>
							{/*end::Card*/}
						</div>
						<div className='col-md-4 col-lg-3'>
							{/*begin::Example*/}
							{/*begin::Card*/}
							<div className='card card-custom'>
								<div className='card-body'>
									<div className='row'>
										<div className='col pr-1'>
											{data.vote !== true ? (
												<button
													type='button'
													className='btn btn-outline-success btn-block px-0'
													onClick={() => Vote(true)}
												>
													<i className='far fa-thumbs-up' />
													Upvote
												</button>
											) : (
												<button
													type='button'
													className='btn btn-success btn-block px-0'
													onClick={() => Vote()}
												>
													<i className='far fa-thumbs-up' />
													Upvoted
												</button>
											)}
										</div>
										<div className='col pl-1'>
											{data.vote !== false ? (
												<button
													type='button'
													className='btn btn-outline-danger btn-block px-0'
													onClick={() => Vote(false)}
												>
													<i
														className='far fa-thumbs-down
'
													/>
													Downvote
												</button>
											) : (
												<button
													type='button'
													className='btn btn-danger btn-block px-0'
													onClick={() => Vote()}
												>
													<i
														className='far fa-thumbs-down
'
													/>
													Downvoted
												</button>
											)}
										</div>
									</div>
									<div className='p-5 text-center'>
										<span className='font-size-h3 d-block font-weight-bolder'>
											{data.points} Points
										</span>
										<div className='font-size-h6 d-block font-weight-bold mb-2 text-dark-50'>
											{data.vote_count} Votes
										</div>
										<button className='btn btn-secondary font-weight-bold btn-pill btn-block'>
											{data.status.toUpperCase()}
										</button>
									</div>
									<hr />{' '}
									<div className='text-center'>
										<span className=''>
											Posted {moment(data.created_at).format('LL')}
										</span>
										<div className='align-items-center'>
											{/* <span className='symbol symbol-circle symbol-success symbol-30 mr-2'>
													<img
														alt='Pic'
														src={toAbsoluteUrl('/media/users/300_21.jpg')}
													/>
												</span> */}
											<span className='text-dark-75 font-weight-bold'>
												{data.created_by?.name.toUpperCase()}
											</span>
										</div>
									</div>
								</div>
							</div>
							{/*end::Card*/}
						</div>
					</>
				) : (
					<center>Idea not found</center>
				)}
			</div>
			<IdeaCreateModal show={show} onHide={onHide} data={data} />
		</>
	)
}
