import axios from 'axios'
const FEEDBACK_API = process.env.REACT_APP_API_URL + '/feedbacks'

export function createFeedback(data) {
	return axios.post(FEEDBACK_API, data)
}

export function updateFeedback(data) {
	return axios.put(FEEDBACK_API, data)
}

export function getAllFeedbacks(queryParams = {}) {
	return axios.get(FEEDBACK_API + '?queryParams=' + JSON.stringify(queryParams))
}

export function getFeedbackById(id) {
	return axios.get(FEEDBACK_API + '/' + id)
}

export function deleteFeedback(id) {
	return axios.delete(FEEDBACK_API + '/' + id)
}
