import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FeedbackCreateModal } from './Create'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
	deleteFeedback,
	fetchFeedback,
	updateFeedback,
} from './_redux/feedbacks/feedbacksActions'
import moment from 'moment'
import { Dropdown } from 'react-bootstrap'

export const FeedbackFetch = ({ setCat }) => {
	const [data, setData] = useState()
	const { id } = useParams()
	const [show, setShow] = useState(false)
	const onHide = () => {
		setShow(false)
	}
	const dispatch = useDispatch()
	const { feedback, feedbacks, isLoading, user } = useSelector(
		({ feedbacks, auth }) => ({
			feedbacks: feedbacks.entities,
			feedback: feedbacks.feedbackForEdit,
			isLoading: feedbacks.actionsLoading,
			user: auth.user,
		}),
		shallowEqual
	)

	useEffect(() => {
		dispatch(fetchFeedback(id))
	}, [dispatch, id])
	useEffect(() => {
		if (feedback?.id === Number(id)) setData(feedback)
		else {
			const d = feedbacks.find((i) => i.id === Number(id))
			setData(d)
		}
	}, [feedbacks, feedback, id])
	const history = useHistory()
	const del = () => {
		dispatch(deleteFeedback(id)).then(() => history.push('/feedbacks'))
	}
	const updateStatus = (status) => {
		dispatch(updateFeedback({ id: Number(id), status }))
	}
	return (
		<>
			<div className='flex-row-fluid ml-lg-8'>
				{!data && isLoading ? (
					<center>
						<span className='m-auto spinner spinner-dark spinner-lg'></span>
					</center>
				) : data ? (
					<>
						<div className='col-lg-12'>
							{/*begin::Card*/}
							<div className='card card-custom mb-5'>
								<div className='card-header border-0'>
									<u className='card-title font-size-h1 font-weight-bolder text-dark'>
										{data.title}
									</u>
									<div className='card-toolbar'>
										<Link
											to='/feedbacks'
											className='btn btn-sm btn-icon btn-bg-light text-secondary btn-hover-secondary mx-1'
											onClick={() => setShow(true)}
										>
											<span className='material-icons'>arrow_back</span>
										</Link>
										{(data.created_by.id === user.user_detail.account_id ||
											user.role_details.role_level <= 4) && (
											<>
												<button
													className='btn btn-sm btn-icon btn-bg-light text-primary btn-hover-primary mx-1'
													onClick={() => setShow(true)}
												>
													<span className='material-icons'>edit</span>
												</button>
												<button
													className='btn btn-sm btn-icon btn-bg-light text-danger btn-hover-danger mx-1'
													onClick={del}
												>
													<span className='material-icons'>delete</span>
												</button>
											</>
										)}
										{user.role_details.role_level <= 4 && (
											<Dropdown
												className='dropdown-inline'
												drop='down'
												alignRight
											>
												<Dropdown.Toggle
													className='btn btn-transparent-primary btn-sm font-weight-bolder dropdown-toggle px-5 text-capitalize'
													variant='transparent'
													id='dropdown-toggle-top'
												>
													{data.status}
												</Dropdown.Toggle>
												<Dropdown.Menu className='dropdown-menu dropdown-menu-sm dropdown-menu-right'>
													<ul className='navi navi-hover'>
														<li className='navi-header pb-1'>
															<span className='text-primary text-uppercase font-weight-bold font-size-sm'>
																Update Status:
															</span>
														</li>
														{[
															'open',
															'in development',
															'in review',
															'delivered',
															'archived',
															'rejected',
															'hidden',
															'closed',
														].map((status, i) => (
															<li
																className='navi-item'
																onClick={() => updateStatus(status)}
																key={i}
															>
																<div className='navi-link'>
																	<span className='navi-text text-capitalize'>
																		{status}
																	</span>
																</div>
															</li>
														))}
													</ul>
												</Dropdown.Menu>
											</Dropdown>
										)}
									</div>
								</div>
								<div className='card-body'>
									{/*begin::Content*/}
									<div className='line-height-lg mb-8'>
										<p
											dangerouslySetInnerHTML={{
												__html: data.description,
											}}
										></p>
										<div>
											<Link
												to='/feedbacks'
												onClick={() => setCat(data.category_id)}
											>
												{' '}
												{data.category}
											</Link>
										</div>
										<small className=''>
											Posted {moment(data.created_at).format('L')} by{' '}
											{data.created_by?.name}
										</small>
									</div>
									{/*end::Content*/}
								</div>
							</div>
							{/*end::Card*/}
						</div>
					</>
				) : (
					<center>Feedback not found</center>
				)}
			</div>
			<FeedbackCreateModal show={show} onHide={onHide} data={data} />
		</>
	)
}
