import axios from 'axios'
const COMMENT_API = process.env.REACT_APP_API_URL + '/comments'

export function createComment(data) {
	return axios.post(COMMENT_API, data)
}

export function updateComment(data) {
	return axios.put(COMMENT_API, data)
}

export function getAllComments(queryParams = {}) {
	return axios.get(COMMENT_API + '?queryParams=' + JSON.stringify(queryParams))
}

export function getCommentById(id) {
	return axios.get(COMMENT_API + '/' + id)
}

export function deleteComment(id) {
	return axios.delete(COMMENT_API + '/' + id)
}

export function vote(data) {
	return axios.post(COMMENT_API + '/vote', data)
}
