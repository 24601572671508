/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { checkIsActive } from '../../../../_helpers'

export function HeaderMenu({ layoutProps }) {
	const { role_level } = useSelector(
		(state) => state.auth.user.role_details,
		shallowEqual,
	)
	const location = useLocation()
	const getMenuItemActive = (url) => {
		return checkIsActive(location, url) ? 'menu-item-active' : ''
	}

	return (
		<div
			id='kt_header_menu'
			className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
			{...layoutProps.headerMenuAttributes}
		>
			{/*begin::Header Nav*/}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				<li
					className={`menu-item menu-item-rel ${getMenuItemActive('/ideas')}`}
				>
					<NavLink className='menu-link' to='/ideas'>
						<span className='menu-text'>Ideas</span>
						{layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
					</NavLink>
				</li>
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				<li
					className={`menu-item menu-item-rel ${getMenuItemActive(
						'/feedbacks',
					)}`}
				>
					<NavLink className='menu-link' to='/feedbacks'>
						<span className='menu-text'>FeedBacks</span>
						{layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
					</NavLink>
				</li>
				{/*end::1 Level*/}
				{/*begin::1 Level*/}
				{role_level <= 4 && (
					<li
						className={`menu-item menu-item-rel ${getMenuItemActive(
							'/categories',
						)}`}
					>
						<NavLink className='menu-link' to='/categories'>
							<span className='menu-text'>Categories</span>
							{layoutProps.rootArrowEnabled && <i className='menu-arrow' />}
						</NavLink>
					</li>
				)}
				{/*end::1 Level*/}
			</ul>
			{/*end::Header Nav*/}
		</div>
	)
}
