import React, { useState } from 'react'
import { Switch } from 'react-router-dom'
import { ContentRoute } from '../../../_metronic/layout'
import { Idea } from './Idea'
import { IdeaFetch } from './FetchPage'

export function IdeaPage() {
	const [cat, setCat] = useState()
	const [initialFilter, setInitialFilter] = useState({
		filter: {},
		sortOrder: 'desc', // asc||desc
		sortField: 'updatedAt',
		pageNumber: 1,
		pageSize: 20,
	})
	const setFilter = (v) =>
		setInitialFilter({
			...initialFilter,
			filter: { ...v },
		})
	return (
		<Switch>
			<ContentRoute path='/ideas/:id'>
				<IdeaFetch setCat={setCat} />
			</ContentRoute>
			<ContentRoute path='/ideas'>
				<Idea
					setFilter={setFilter}
					initialFilter={initialFilter}
					cat={cat}
					setInitialFilter={setInitialFilter}
				/>
			</ContentRoute>
		</Switch>
	)
}
