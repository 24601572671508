import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IdeaCreateModal } from './Create'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { fetchIdeas } from './_redux/ideas/ideasActions'
import moment from 'moment'
import { CategorySidebar } from '../../components/CategorySidebar'
import { Pagination } from '../../../_metronic/_partials/controls'
import { sizePerPageList } from '../../components/Helper'

export const Idea = ({ initialFilter, setFilter, cat, setInitialFilter }) => {
	const [show, setShow] = useState(false)
	const onHide = () => {
		setShow(false)
	}
	const dispatch = useDispatch()
	const { currentState } = useSelector(
		({ ideas }) => ({
			currentState: ideas,
		}),
		shallowEqual
	)
	const { entities, listLoading, totalCount } = currentState
	useEffect(() => {
		dispatch(fetchIdeas(initialFilter))
	}, [dispatch, initialFilter])
	return (
		<>
			<div className='d-flex flex-column-fluid'>
				{/*begin::Container*/}
				{/*begin::Profile Personal Information*/}
				<CategorySidebar
					setFilter={setFilter}
					filter={initialFilter.filter}
					cat={cat}
				/>
				{/*end::Aside*/}
				{/*begin::Content*/}
				<div className='col-lg-9 ml-lg-8'>
					{/*begin::Card*/}
					<div className='card card-custom card-stretch'>
						{/*begin::Header*/}
						<div className='card-header py-3'>
							<div className='card-title align-items-start flex-column'>
								<h3 className='card-label font-weight-bolder text-dark'>
									Ideas
								</h3>
								<span className='text-muted font-weight-bold font-size-sm mt-1'>
									Share your ideas with us to make us serve you better
								</span>
							</div>
							<div className='card-toolbar'>
								<button
									type='button'
									onClick={() => setShow(true)}
									className='btn btn-primary mr-2'
								>
									New
								</button>
							</div>
						</div>
						{/*end::Header*/}
						<div className='card-body'>
							<Pagination
								isLoading={listLoading}
								paginationProps={{
									totalSize: totalCount,
									dataSize: totalCount,
									sizePerPage: initialFilter.pageSize,
									page: initialFilter.pageNumber,
									paginationSize: totalCount / initialFilter.pageSize,
									sizePerPageList: sizePerPageList,
									pageStartIndex: 1,
									custom: true,
									onPageChange: (v) => {
										setInitialFilter({ ...initialFilter, pageNumber: v })
									},
									onSizePerPageChange: (v) => {
										setInitialFilter({
											...initialFilter,
											pageNumber: 1,
											pageSize: v,
										})
									},
								}}
							>
								{entities.length > 0
									? entities.map((idea, i) => (
											<div key={i}>
												<div className='row my-5'>
													{/*begin: Pricing*/}
													<div className='col-md-9 col-xxl-9 border-right-0 border-right-md border-bottom border-bottom-xxl-0'>
														{/*begin::Content*/}
														<div className='mb-4'>
															<div>
																<Link
																	to={`/ideas/${idea.id}`}
																	className='font-size-h4 font-weight-bolder'
																>
																	{idea.title}
																</Link>
															</div>
															<small className='font-weight-bolder'>
																{idea.category}
															</small>
														</div>
														<div className='line-height-lg mb-8'>
															<p
																dangerouslySetInnerHTML={{
																	__html: idea.description
																		.substring(0, 300)
																		.concat(
																			idea.description.length > 300
																				? ' ...'
																				: ''
																		),
																}}
															></p>
															<small className=''>
																Posted {moment(idea.created_at).format('L')} by{' '}
																{idea.created_by?.name}
															</small>
														</div>
														{/*end::Content*/}
													</div>
													{/*end: Pricing*/}
													{/*begin: Pricing*/}
													<div className='col-md-3 col-xxl-3'>
														<div className='p-5 text-center'>
															<span className='font-size-h1 d-block font-weight-boldest text-dark-75 pt-2'>
																{idea.points}
															</span>
															<div className='font-size-h6 d-block font-weight-bold mb-2 text-dark-50'>
																Points
															</div>
															<button className='btn btn-secondary font-weight-bold btn-pill btn-block'>
																{idea.status.toUpperCase()}
															</button>
														</div>
													</div>
													{/*end: Pricing*/}
												</div>
												<hr />
											</div>
									  ))
									: !listLoading && <center>No Ideas at the moment</center>}
							</Pagination>
						</div>
					</div>
				</div>
				{/*end::Content*/}
			</div>
			<IdeaCreateModal show={show} onHide={onHide} />
		</>
	)
}
