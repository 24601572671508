import React from 'react'
export function MobilePage() {
	return (
		<div className='h-100 ml-3 mr-3 d-flex align-items-center justify-content-center'>
			<h3 style={{ textAlign: 'center' }}>
				Sorry! This Application is not available on a Mobile Device yet. Use
				either a PC or Tablet to continue...
			</h3>
		</div>
	)
}
