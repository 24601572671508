import React, { Suspense, useEffect } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { Category } from './pages/Category/Category'
import { FeedbackPage } from './pages/Feedback/FeedbackPage'
import { useDispatch } from 'react-redux'
import { fetchCategories } from './pages/Category/_redux/categories/categoriesActions'
import { IdeaPage } from './pages/Idea/IdeaPage'

export default function BasePage() {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchCategories({}))
	}, [dispatch])
	return (
		<div className='container mt-15'>
			<Suspense fallback={<LayoutSplashScreen />}>
				<Switch>
					<Redirect exact from='/' to='/ideas' />
					<ContentRoute path='/categories' component={Category} />
					<ContentRoute path='/feedbacks' component={FeedbackPage} />
					<ContentRoute path='/ideas' component={IdeaPage} />
					<Redirect to='/error' />
				</Switch>
			</Suspense>
		</div>
	)
}
