import * as requestFromServer from './categoriesCrud'
import { categoriesSlice, callTypes } from './categoriesSlice'

const { actions } = categoriesSlice

export const fetchCategories = (queryParams) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.list }))
	try {
		const response = await requestFromServer.getAllCategories(queryParams)
		dispatch(actions.categoriesFetched({ ...response }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.list }))
		throw error
	}
}

export const createCategory = (categoryForCreation) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		const data = await requestFromServer.createCategory(categoryForCreation)
		dispatch(actions.categoryCreated({ data }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}

export const updateCategory = (category) => async (dispatch) => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	try {
		await requestFromServer.updateCategory(category)
		dispatch(actions.categoryUpdated({ category }))
	} catch (error) {
		dispatch(actions.catchError({ error, callType: callTypes.action }))
		throw error
	}
}
