/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { checkIsActive } from '../../../../_helpers'

export function AsideMenuList({ layoutProps }) {
	const { role_level } = useSelector(
		(state) => state.auth.user.role_details,
		shallowEqual,
	)
	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}

				{/* Admin Settings */}
				{/* begin::section */}
				<li className='menu-section '>
					<h4 className='menu-text'>Application</h4>
					<i className='menu-icon flaticon-more-v2'></i>
				</li>
				{/* end:: section */}

				<>
					{/*end::1 Level*/}
					<li
						className={`menu-item ${getMenuItemActive('/ideas', false)}`}
						aria-haspopup='true'
					>
						<NavLink className='menu-link' to='/ideas'>
							<span className='svg-icon menu-icon'>
								<span className='material-icons'>tips_and_updates</span>
							</span>
							<span className='menu-text'>Ideas</span>
						</NavLink>
					</li>
					{/*end::1 Level*/}
					{/*begin::1 Level*/}
					<li
						className={`menu-item ${getMenuItemActive('/feedbacks', false)}`}
						aria-haspopup='true'
					>
						<NavLink className='menu-link' to='/feedbacks'>
							<span className='svg-icon menu-icon'>
								<span className='material-icons'>forum</span>
							</span>
							<span className='menu-text'>Feedbacks</span>
						</NavLink>
					</li>
					{role_level <= 4 && (
						<li
							className={`menu-item ${getMenuItemActive('/categories', false)}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/categories'>
								<span className='svg-icon menu-icon'>
									<span className='material-icons'>category</span>
								</span>
								<span className='menu-text'>Categories</span>
							</NavLink>
						</li>
					)}
					{/*end::1 Level*/}
				</>
			</ul>
			{/* end::Menu Nav */}
		</>
	)
}
